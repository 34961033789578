import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './App.scss';

// Google Analytics & History
import ReactGA from 'react-ga4';

// Components
import { Footer, Header, Main } from './components';

export function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: location.pathname,
    });
  }, [location.pathname]);

  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}
