import { useEffect, useState } from 'react';
import { FaBars, FaEnvelope, FaTimes } from 'react-icons/fa';
// useLocation
import { NavLink } from 'react-router-dom';

export function Header() {
  // const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Handle scroll effect for sticky header
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  // Prevent scrolling when mobile menu is open
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [menuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header
      className={`site-header ${scrolled ? 'scrolled' : ''} ${menuOpen ? 'menu-open' : ''}`}
    >
      <div className="header-container">
        <div className="header-logo">
          <NavLink to="/" onClick={closeMenu}>
            <h1>Shumunov Solutions</h1>
          </NavLink>
        </div>

        <nav className="header-nav desktop-nav">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? 'active' : '')}
            onClick={closeMenu}
          >
            Home
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) => (isActive ? 'active' : '')}
            onClick={closeMenu}
          >
            Services
          </NavLink>
          <NavLink
            to="/work"
            className={({ isActive }) => (isActive ? 'active' : '')}
            onClick={closeMenu}
          >
            Our Work
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) => (isActive ? 'active' : '')}
            onClick={closeMenu}
          >
            Contact
          </NavLink>
        </nav>

        <button
          className="mobile-menu-toggle"
          onClick={toggleMenu}
          aria-label={menuOpen ? 'Close menu' : 'Open menu'}
        >
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Mobile Navigation */}
        <div className="mobile-nav-wrapper">
          <nav className="header-nav mobile-nav">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={closeMenu}
            >
              Home
            </NavLink>
            <NavLink
              to="/services"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={closeMenu}
            >
              Services
            </NavLink>
            <NavLink
              to="/work"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={closeMenu}
            >
              Our Work
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={closeMenu}
            >
              Contact
            </NavLink>

            <div className="mobile-nav-footer">
              <div className="mobile-contact">
                <FaEnvelope />
                <a href="mailto:info@ShumunovSolutions.com">
                  info@ShumunovSolutions.com
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
