import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import './index.css';

// TODO: Move this to env
const trackingId = 'G-DFXW4FR1BJ';
ReactGA.initialize(trackingId);

if (window.matchMedia('(prefers-color-scheme: dark)').matches === true) {
  console.log(
    '%c%s',
    'color: red; font-family: system-ui; font-size: 2rem; font-weight: bold',
    'Welcome to Shumunov Solutions!'
  );

  console.log(
    "%cYou're pretty advanced to come snooping here. Looking for some errors? 😝 In any regard, well done 👏 , feel free to email us at info@shumunovsolutions.com with any feedback, if you'd like to work together, or just to say hello!",
    'color: white; font-family: system-ui; font-size: 1rem;'
  );
} else {
  console.log(
    '%c%s',
    'color: blue; font-family: system-ui; font-size: 2rem; -webkit-text-stroke: 1px black; font-weight: bold',
    'Welcome to Shumunov Solutions!'
  );

  console.log(
    "%cYou're pretty advanced to come snooping here. Looking for some errors? 😝 In any regard, well done 👏 , feel free to email us at info@shumunovsolutions.com with any feedback, if you'd like to work together, or just to say hello!",
    'color: black; font-family: system-ui; font-size: 1rem;'
  );
}

const root = document.getElementById('root');
ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
