import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

// Lazy Loading & default exports for Page Components
const Landing = lazy(() =>
  import('../Landing').then((module) => ({ default: module.Landing }))
);
const Contact = lazy(() =>
  import('../Contact').then((module) => ({ default: module.Contact }))
);
const Work = lazy(() =>
  import('../Work').then((module) => ({ default: module.Work }))
);
const Services = lazy(() =>
  import('../Services').then((module) => ({ default: module.Services }))
);
const NotFound = lazy(() =>
  import('../NotFound').then((module) => ({ default: module.NotFound }))
);

export function Main() {
  return (
    <main>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/work" element={<Work />} />
          <Route path="/services" element={<Services />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </main>
  );
}
