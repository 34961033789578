import { useEffect, useState } from 'react';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const currentYear = new Date().getFullYear();

export function Footer() {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentSearchParams, setCurrentSearchParams] = useState(
    location.search
  );

  useEffect(() => {
    setCurrentSearchParams(location.search);
  }, [location.search]);

  // Handle service link clicks to avoid default NavLink behavior
  const handleServiceClick = (tab, e) => {
    e.preventDefault();
    navigate(`/services?tab=${tab}`);
  };

  const isActiveTab = (tab) => {
    if (location.pathname !== '/services') return false;
    const params = new URLSearchParams(currentSearchParams);
    const currentTab = params.get('tab') || 'development';
    return currentTab === tab;
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column">
          <div className="footer-logo">
            <h2>Shumunov Solutions</h2>
            <p>Software expertise for modern businesses</p>
          </div>
          {/* TODO: Add social links once done */}
          {/* <div className="social-links">
            <a
              href="https://linkedin.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://github.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="GitHub"
            >
              <FaGithub />
            </a>
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <FaTwitter />
            </a>
          </div> */}
        </div>

        <div className="footer-column">
          <h3>Navigation</h3>
          <nav className="footer-nav">
            <NavLink
              to="/"
              className={location.pathname === '/' ? 'active' : ''}
            >
              Home
            </NavLink>
            <NavLink
              to="/services"
              className={location.pathname === '/services' ? 'active' : ''}
            >
              Services
            </NavLink>
            <NavLink
              to="/work"
              className={location.pathname === '/work' ? 'active' : ''}
            >
              Our Work
            </NavLink>
            <NavLink
              to="/contact"
              className={location.pathname === '/contact' ? 'active' : ''}
            >
              Contact
            </NavLink>
          </nav>
        </div>

        <div className="footer-column">
          <h3>Services</h3>
          <nav className="footer-nav">
            <a
              href="/services?tab=development"
              className={isActiveTab('development') ? 'active' : ''}
              onClick={(e) => handleServiceClick('development', e)}
            >
              Web Development
            </a>
            <a
              href="/services?tab=mobile"
              className={isActiveTab('mobile') ? 'active' : ''}
              onClick={(e) => handleServiceClick('mobile', e)}
            >
              Mobile Applications
            </a>
            <a
              href="/services?tab=consulting"
              className={isActiveTab('consulting') ? 'active' : ''}
              onClick={(e) => handleServiceClick('consulting', e)}
            >
              Technical Consulting
            </a>
            <a
              href="/services?tab=other"
              className={isActiveTab('other') ? 'active' : ''}
              onClick={(e) => handleServiceClick('other', e)}
            >
              Custom Solutions
            </a>
          </nav>
        </div>

        <div className="footer-column">
          <h3>Contact Us</h3>
          <div className="contact-info">
            <div className="contact-item">
              <FaEnvelope />
              <a href="mailto:info@ShumunovSolutions.com">
                info@ShumunovSolutions.com
              </a>
            </div>
            <div className="contact-item">
              <FaMapMarkerAlt />
              <p className="address">
                South East, MI - Remotely across the globe
              </p>
            </div>
            <a href="/contact" className="contact-button">
              Get in Touch
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="copyright">
          &copy; {currentYear} Shumunov Solutions. All Rights Reserved.
        </div>
        {/* TODO: Add footer legal pages */}
        {/* <div className="footer-legal">
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms of Service</a>
        </div> */}
      </div>
    </footer>
  );
}
